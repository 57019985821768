@import "styles/core.scss";

.confirmation-modal {
  .modal-content {
    padding: 0 38px;
  }
  h3 {
    color: $primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }
  p {
    font-size: 16px;
    color: $gray1;
    margin: 10px 0;
    font-weight: bold;
  }
  ul {
    list-style: initial;
    li {
      font-size: 16px;
      color: $gray1;
      padding: initial;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}

.confirmation-done-button {
    height: 48px;
    width: 93px;
    background-color: $secondary;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    &:hover,
    &:visited,
    &:active {
      background-color: $secondary;
      color: #fff;
    }
  }