@import "styles/core.scss";

.welcome-container {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  padding-top: 58px; // todo: merge paddings
  @include mq($until: tablet) {
    justify-content: space-between;
    padding-top: 44px;
  }
  &--heading {
    font-weight: 700;
    color: $primary;
    max-width: 499px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 30px;  
  }
  &--description {
    font-size: 16px;
    color: $gray1;
    line-height: 19.5px;
  }
  &--highlight {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    color: #F72585;
    @include mq($until: tablet) {
      &.desktop-view {
        display: none;
      }
    }
    @include mq($from: tablet) {
      &.mobile-view {
        display: none;
      }
    }
  }
}

.links-wrapper {
  a:hover {
    cursor: pointer;
  }
}