@import "styles/core.scss";

.registration-form {
  @include mq($until: tablet) {
    top: 24px;
    max-width: calc(100vw - 48px);
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  &--content {
    padding: 0 38px;
    @include mq($until: desktop) {
      max-height: fit-content;
      overflow-y: scroll;
    }

    h3 {
      font-weight: 700;
      color: $primary;
      font-size: 24px;
    }
    p {
      color: $gray2;
      font-size: 16px;
    }
    h4 {
      color: $gray1;
      font-weight: 700;
      font-size: 16px;
    }
    .ant-input {
      display: flex;
      align-items: center;
      height: 43px;
      border-radius: 8px;
      border: 1px solid #cdd6e1;
      font-size: 16px;
      &:disabled {
        background-color: $gray3;
        color: #cdd6e1;
      }
    }

    .custom-input-autofill {
      .input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }
}

.registration-submit-button {
  height: 48px;
  background-color: $secondary;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  &:hover,
  &:visited,
  &:active {
    background-color: $secondary;
    color: #fff;
  }
}