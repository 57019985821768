@import "styles/core.scss";

.banner-wrapper {
  position: relative;
  height: 435px;
  background: url("../../assets/images/logo.png") no-repeat;
  background-size: cover;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include mq($until: tablet) {
    height: 338px;
  }
  h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #fff;
    max-width: 750px;
    margin-bottom: 25px;
    @include mq($until: tablet) {
      font-size: 32px;
      line-height: 39px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    max-width: 750px;
    margin-bottom: 25px;
    @include mq($until: tablet) {
      font-size: 16px;
      line-height: 29px;
    }
  }
}

.banner-content {
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: $primary;
  mix-blend-mode: multiply;
}

.hub-banner-cta-button {
  display: inline-flex;
  background-color: $secondary;
  height: 48px;
  padding: 0 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: 160px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  position: relative;
}

.demo-popover {
  border-radius: 16px;
  padding: 0 !important;
  margin-top: -10px;
}

.hub-popover-wrapper {
  padding: 20px 30px;
  p {
    padding: 0;
    margin: 0;
    text-align: left;
    margin-bottom: 15px;
    line-height: 18px;
    &.strong-text {
      font-weight: 600;
    }
  }
  .popover-footer {
    display: flex;
    justify-content: flex-end;
  }
}
