@import "styles/core.scss";

.mobile-banner {
  display: flex;
  height: 152px;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(#000, 0.2);
  margin: 30px 0;

  &--bg-image {
    width: 280px;
    background-image: url("../../assets/images/phones-cut.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  &--promotion-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 39px;
    padding-right: 20px;
    flex: 1;
    h3 {
      margin: 0 0 12px 0;
      font-weight: 600;
      font-size: 16px;
      color: $gray1;
      padding: 0;
      line-height: 19.5px;
      max-width: 287px;
    }
    p {
      font-size: 16px;
      margin: 0;
      padding: 0;
      max-width: 287px;
      color: $gray2;
      line-height: 19.5px;
    }
  }
  &--download-links {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .app-store-img,
    .play-store-img {
      height: 45px;
      img {
        max-height: 100%;
      }
    }
  }
  @include mq($until: desktop) {
    flex-direction: column;
    padding: 30px 0;
    height: auto;
    &--bg-image {
      display: none;
    }
    &--promotion-text {
      margin-bottom: 12px;
      h3, p {
        max-width: initial;
      }
    }
    &--download-links {
      margin: 15px 38px 0;
      width: auto;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      img {
        max-width: 100%;
      }
      .app-store-img {
        margin-right: 10px;
      }
    }
  }
}
