.registration-error-box {
  background-color: #f03c3c;
  border-radius: 8px;
  padding: 8px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px;
  span {
    text-align: center;
    display: inline-block;
    display: block;
    color: #ffffff;
    max-width: 414px;
    font-size: 14px;
    line-height: 17px;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
