@import "styles/core.scss";

header {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mq($until: tablet) {
    flex-direction: column-reverse;
  }
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  @include mq($until: tablet) {
    height: 112px;
    padding: 5px 11px;
  }
}

.header-menu {
  @include mq($until: desktopExtended) {
    display: none;
  }

  &--item {
    display: inline-flex;
    align-items: center;
    .icon {
      line-height: 1;
      margin-right: 9px;
    }
    .label {
      color: $gray1;
      font-weight: 600;
      font-size: 16px;
      line-height: 19.5px;
    }
  }
}

.enrollment-button {
  height: 48px;
  display: inline-flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 8px;
  background-color: #f72585;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  border: 0;
  cursor: pointer;
}

.information-bar {
  padding: 12px 32px;
  display: flex;
  justify-content: flex-end;
  @include mq($until: tablet) {
    justify-content: space-between;
    padding: 2px 11px 15px;
  }
  &--item {
    color: $gray2;
    font-size: 14px;
    @include mq($until: tablet) {
      font-size: 12px;
    }
    &:first-child {
      margin-right: 24px;
    }
  }
}

.header-body-separator {
  height: 51px;
  background-color: $primary;
  @include mq($until: tablet) {
    height: 64px;
  }
}

/**
  Layout
*/

.layout-wrapper {
  &--content {
    display: flex;
    flex-direction: column;
  }
}

.layout-content-container {
  max-width: 886px;
  margin: 0 auto;
  @include mq($until: desktop) {
    padding: 0 32px;
    max-width: auto;
  }
}

/**
Footer and elements
*/

footer {
  height: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 32px;
  background: $gray3;
  .copyright-text {
    color: $gray2;
    font-size: 14px;
    line-height: 17px;
  }
  @include mq($from: tablet, $until: desktop) {
    .copyright-text {
      width: 158px;
    }
  }

  @include mq($until: tablet) {
    height: 174px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .copyright-text {
      width: 261px;
    }
    .logo {
      margin-bottom: 25px;
    }
  }
}

.welcome-container--highlight {
  @include mq($until: tablet) {
    text-align: center;
  }
}

.desktop-view {
  &.hub {
    @include mq($until: desktop) {
      margin-bottom: 30px;
    }
  }
}

.welcome-container-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 58px;
  @include mq($from: mobile, $until: tablet) {
    flex-direction: column;
    margin-top: 40px;
  }
}

.description {
  flex: 60%;
  @include mq($from: tablet, $until: desktop) {
    flex: 50%;
  }
  // @include mq($from: desktop) {
  //   margin-top: 58px;
  // }
}
