@import "styles/core.scss";

.phones-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq($from: mobile, $until: tablet) {
    margin-top: 30px
  }
  @include mq($from: tablet, $until: desktop) {
    flex: 50%;
  }
  @include mq($from: desktop) {
    flex: 40%;
    margin-top: -110px;
  }
  z-index: 1;
  img {
    height:337px;
  }
}

.phones-desc {
  h4 {
    font-weight: 700;
    color: $gray1;
    text-align: center;
    margin-bottom: 7px;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: $gray2;
    text-align: center;
  }
}
